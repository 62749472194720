<template>
	<td>
		
		<span v-if="data.Weight">
			{{((data.Weight) * (truck_job.status_id >= 1 ? t.loaded_amount : t.Qty)).toFixed(2)}}
			<span class="has-text-grey-mid-light">Kg</span>
		</span>
		<span v-else>-</span>
	</td>
</template> 
  
<script>
	export default{
		props: ["data", "t", "truck_job"]
	}
</script>

<style scoped>

</style>

